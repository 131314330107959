
import SAutocomplete from '@/components/s/autocomplete'
import { mergeProps } from '@/utils/mergeData'
import { VIcon, VListItemContent, VListItemTitle } from 'vuetify/lib/components'
import { getPropertyFromItem } from 'vuetify/lib/util/helpers'
import VArchivedIcon from '@/components/s/archived-icon.vue'
import { ParticipantSubjectIcons } from '@/utils/smarteh/tasks/participants'

const COMPANY_PREFIX = 'c'
const GROUP_PREFIX = 'g'
const EMPLOYEE_PREFIX = 'e'

export default SAutocomplete.extend({
  name: 'participant-autocomplete',
  props: {
    companiesItems: {
      type: Array,
      default: () => ([]),
    },
    groupsItems: {
      type: Array,
      default: () => ([]),
    },
    employeesItems: {
      type: Array,
      default: () => ([]),
    },
    companiesValue: {
      type: [Number, Array, Object],
    },
    groupsValue: {
      type: [Number, Array, Object],
    },
    employeesValue: {
      type: [Number, Array, Object],
    },

    label: {
      type: [String, Array],
      default: 'ET.task_stage_participant.PL',
    },
    icon: {
      type: String,
      default: '$employee',
    },
    outlined: mergeProps(SAutocomplete.options.props.outlined, {
      default: true,
    }),
    autoSelectFirst: mergeProps(SAutocomplete.options.props.autoSelectFirst, {
      default: true,
    }),
    returnObject: mergeProps(SAutocomplete.options.props.returnObject, {
      validator (v) {
        // TODO: Add support return-object: true
        return v === false
      }
    }),
    multiple: mergeProps(SAutocomplete.options.props.multiple, {
      validator (v) {
        // TODO: Add support multiple: false
        return v === true
      }
    }),
    headerGroupBy: mergeProps(SAutocomplete.options.props.headerGroupBy, {
      default: 'company.company_id',
    }),
    headerText: mergeProps(SAutocomplete.options.props.headerText, {
      default: 'company.name'
    }),
  },
  computed: {
    allItems () {
      if (this.items?.length) {
        this.items
      }

      return [
        ...this.companiesItems.map((company) => ({
          text: company.name,
          value: `${COMPANY_PREFIX}_${company.company_id}`,
          company: company,
          archived: company.archived || false,
          type: 'company',
        })),
        ...this.groupsItems.map((group) => ({
          text: group.name,
          value: `${GROUP_PREFIX}_${group.group_id}`,
          company: group.company,
          archived: group.archived || false,
          type: 'group',
        })),
        ...this.employeesItems.map((employee) => ({
          text: this.$employee_title(employee),
          value: `${EMPLOYEE_PREFIX}_${employee.employee_id}`,
          company: employee.company,
          archived: employee.archived || false,
          type: 'employee',
        })),
      ]
    },
    allValues () {
      return [
        ...(this.companiesValue || []).map(v => `c_${v}`),
        ...(this.groupsValue || []).map(v => `g_${v}`),
        ...(this.employeesValue || []).map(v => `e_${v}`),
      ]
    },
    listData () {
      const data = SAutocomplete.options.computed.listData.call(this)

      data.scopedSlots.item =
        ({ item }) => this.$createElement(
          VListItemContent,
          [
            this.$createElement(
              VListItemTitle,
              {
                class: {
                  'font-italic font-weight-regular': getPropertyFromItem(
                    item,
                    this.itemValue
                  ) === this.nullItemValue,
                },
              },
              [
                this.getItemIcon(item),
                this.getText(item),
                getPropertyFromItem(item, this.itemArchived)
                  ? this.$createElement(VArchivedIcon, {
                    props: {
                      xSmall: true,
                      iconClass: 'mt-n1 ml-1',
                    },
                  })
                  : null,
              ],
            ),
          ],
        )

      return data
    }
  },
  methods: {
    setValue (value) {
      if (!this.valueComparator(value, this.internalValue)) {
        this.internalValue = value
        this.$emit('change', value)

        const companiesValue = []
        const groupsValue = []
        const employeesValue = []

        for (const k of value) {
          const matched = String(k).match(/^([a-z])_(\d+)$/)
          if (!matched?.length) continue

          switch (matched[1]) {
            case COMPANY_PREFIX: {
              companiesValue.push(+matched[2])
              break
            }
            case GROUP_PREFIX: {
              groupsValue.push(+matched[2])
              break
            }
            case EMPLOYEE_PREFIX: {
              employeesValue.push(+matched[2])
              break
            }
          }
        }

        this.$emit('update:companies-value', companiesValue)
        this.$emit('update:groups-value', groupsValue)
        this.$emit('update:employees-value', employeesValue)
      }
    },
    getItemIcon (item) {
      const icon = ParticipantSubjectIcons[item?.type] || '$user'

      return this.$createElement(VIcon, {
          props: {
            xSmall: true,
          },
          class: {
            'mt-n05': true,
            'mr-1': true,
          }
        }, [icon]
      )
    },
    genChipSelection(item, index) {
      const chip = SAutocomplete.options.methods.genChipSelection.call(this, item, index)
      chip.componentOptions.children.unshift(
        this.getItemIcon(item)
      )
      return chip
    }
  },
  watch: {
    allValues: {
      handler (v) {
        this.lazyValue = v
      },
      immediate: true,
    },
  }
})
