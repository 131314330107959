
import { mapState, mapActions, mapMutations } from 'vuex'
import { urgencies } from '@/utils/smarteh/tasks/urgencies'
import { stages, statuses, statusesByKey } from '@/utils/smarteh/tasks/stages'
import { debounce, isEqual, snakeCase } from 'lodash'
import { objectsListFromClients } from '@/mixins/objects'
import { getDefaultFilter } from '@/utils/smarteh/tasks/filter'
import { ParticipantTypes as participant_types } from '@/utils/smarteh/tasks/participants'
import { useComputedStateAndMutation } from '@/mixins/vuex'

const groupFilters = {
  fast: [
    'search',
    'object_id',
    'stage_participants_company_id',
    'stage_participants_group_id',
    'stage_participants_employee_id',
    'stage_type',
  ],
  task: [
    'search',
    'client_id',
    'object_id',
    'contractor_id',
    'category_id',
    'urgency',
    'activity_code_id',
    'reason_id',
    'applicant_id',
    'favorite',
    'overdue',
    'submitted_at',
    'deadline_at',
    'finished_at',
    'created_at',
  ],
  stage: [
    'stage_type',
    'stage_status',
    'stage_responsible_id',
    'stage_subcontractor_id',
    'stage_participants_company_id',
    'stage_participants_group_id',
    'stage_participants_group_employee_id',
    'stage_participants_employee_id',
    'stage_participants_employee_group_id',
    'stage_participants_type',
    'stage_created_by',
    'stage_overdue',
    'stage_deadline_at',
    'stage_created_at',
  ],
  stages: [
    'stages_type',
    'stages_status',
    'stages_responsible_id',
    'stages_subcontractor_id',
    'stages_participants_company_id',
    'stages_participants_group_id',
    'stages_participants_group_employee_id',
    'stages_participants_employee_id',
    'stages_participants_employee_group_id',
    'stages_participants_type',
    'stages_created_by_id',
    'stages_finished_by_id',
    'stages_created_at',
    'stages_finished_at',
  ],
}

const preloadMap = {
  fetchFilterClients: ['client_id', 'object_id'],
  fetchFilterContractors: ['contractor_id'],
  fetchFilterCategories: ['category_id'],
  fetchFilterActivityCodes: ['activity_code_id'],
  fetchFilterReasons: ['reason_id'],
  fetchFilterStagesResponsibles: ['stages_responsible_id', 'stage_responsible_id'],
  fetchFilterStagesSubcontractors: ['stages_subcontractor_id', 'stage_subcontractor_id'],
  fetchFilterStagesParticipantsEmployees: ['stages_participants_employee_id', 'stage_participants_employee_id'],
  fetchFilterStagesParticipantsEmployeesGroups: [
    'stages_participants_employee_group_id',
    'stage_participants_employee_group_id'],
  fetchFilterStagesParticipantsGroups: ['stages_participants_group_id', 'stage_participants_group_id'],
  fetchFilterStagesParticipantsGroupsEmployees: [
    'stages_participants_group_employee_id',
    'stage_participants_group_employee_id'],
  fetchFilterStagesParticipantsCompanies: ['stages_participants_company_id', 'stage_participants_company_id'],
  fetchFilterStagesCreatedBy: ['stages_created_by_id', 'stage_created_by_id'],
  fetchFilterStagesFinishedBy: ['stages_finished_by_id'],
}
const propertyToItems = Object.keys(preloadMap).reduce((o, f) => {
  o[f] = snakeCase(f.substring(11))
  return o
}, {})

export default {
  name: 'tasks-filter',
  provide () {
    return {
      filter: this,
    }
  },
  mixins: [
    useComputedStateAndMutation('showFilter', 'tasks.show_filter', 'tasks/SET_SHOW_FILTER'),
  ],
  data () {
    return {
      filter: this.getDefaultFilter(),
      filter_prev: this.getDefaultFilter(), // Для сравнения изменений
      expansion_filter_categories: [],
      urgency: urgencies,
      search_applicant: '',
      search_object: '',
      stage_types: stages,
      stage_statuses: statuses,
      showFilterHint: false,
      participant_types,
    }
  },
  created () {
    this.showFilter = this.$vuetify.breakpoint.mdAndUp
  },
  computed: {
    ...mapState({
      tasks: state => state.tasks,
    }),
    width () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xl':
          return 400
        default:
          return 300
      }
    },
    current_filter: {
      get () {
        return this.$store.state.tasks.filter
      },
      set (val) {
        this.$store.commit('tasks/SET_FILTER', val)
      },
    },
    objects () {
      if (!this.tasks.filter_options.clients) return []
      return objectsListFromClients.call(
        this,
        !this.filter.client_id.length
          ? this.tasks.filter_options.clients
          : this.tasks.filter_options.clients.filter(c => this.filter.client_id.includes(c.company_id)),
        this.search_object,
      )
    },
    changed () {
      return !isEqual(this.filter, this.filter_prev)
    },
    active_stage_statuses () {
      return ['null', 'DOING', 'WAITING'].map(key => statusesByKey[key])
    },
    countGroupFilters () {
      const result = {}
      for (const key in groupFilters) {
        result[key] = groupFilters[key].reduce((sum, f) => {
          if (
            typeof this.filter[f] === 'boolean'
            || Array.isArray(this.filter[f]) && this.filter[f].length
            || typeof this.filter[f] === 'string' && this.filter[f]
          ) {
            sum++
          }
          return sum
        }, 0)
      }
      return result
    },
  },
  methods: {
    ...mapActions({
      fetchFilterOptions: 'tasks/fetchFilterOptions',
      fetchFilterClients: 'tasks/fetchFilterClients',
      fetchFilterContractors: 'tasks/fetchFilterContractors',
      fetchFilterCategories: 'tasks/fetchFilterCategories',
      fetchFilterStagesCreatedBy: 'tasks/fetchFilterStagesCreatedBy',
      fetchFilterStagesFinishedBy: 'tasks/fetchFilterStagesFinishedBy',
      fetchFilterStagesResponsibles: 'tasks/fetchFilterStagesResponsibles',
      fetchFilterStagesSubcontractors: 'tasks/fetchFilterStagesSubcontractors',
      fetchFilterStagesParticipantsCompanies: 'tasks/fetchFilterStagesParticipantsCompanies',
      fetchFilterStagesParticipantsGroups: 'tasks/fetchFilterStagesParticipantsGroups',
      fetchFilterStagesParticipantsGroupsEmployees: 'tasks/fetchFilterStagesParticipantsGroupsEmployees',
      fetchFilterStagesParticipantsEmployees: 'tasks/fetchFilterStagesParticipantsEmployees',
      fetchFilterStagesParticipantsEmployeesGroups: 'tasks/fetchFilterStagesParticipantsEmployeesGroups',
      fetchFilterApplicants: 'tasks/fetchFilterApplicants',
      fetchFilterActivityCodes: 'tasks/fetchFilterActivityCodes',
      fetchFilterReasons: 'tasks/fetchFilterReasons',
    }),
    fetchFilterStagesParticipants () {
      return Promise.all([
        this.tasks.filter_options.stages_participants_companies?.length
        || this.fetchFilterStagesParticipantsCompanies(),
        this.tasks.filter_options.stages_participants_groups?.length
        || this.fetchFilterStagesParticipantsGroups(),
        this.tasks.filter_options.stages_participants_employees?.length
        || this.fetchFilterStagesParticipantsEmployees(),
      ])
    },
    ...mapMutations({
      applyFilter: 'tasks/SET_FILTER',
    }),
    async preload () {
      for (const func in preloadMap) {
        if (preloadMap[func].some((k) => this.filter[k].length) &&
          !this.tasks.filter_options[propertyToItems[func]]?.length) {
          this[func]()
        }
      }
    },
    submitFilter () {
      this.applyFilter(this.filter)
    },
    getDefaultFilter () {
      return {
        ...getDefaultFilter(),
        search_fields: ['description', 'comments'],
      }
    },
    clearFilter () {
      this.filter = this.getDefaultFilter()
      this.submitFilter()
    },
    fetchApplicants: debounce(function () {
      const params = {}
      if (this.search_applicant) params.search = this.search_applicant
      if (this.filter.client_id && this.filter.client_id.length) params.client_id = this.filter.client_id.join(',')
      if (this.filter.object_id && this.filter.object_id.length) params.object_id = this.filter.object_id.join(',')
      if (Object.keys(params).length < 1) return false
      this.$store.commit('tasks/SET_FILTER_OPTIONS', { applicants: null })
      return this.fetchFilterApplicants(params)
    }, 300),
  },
  watch: {
    'search_applicant' () {
      this.fetchApplicants()
    },
    'tasks.filter': {
      handler () {
        this.filter = { ...this.getDefaultFilter(), ...this.tasks.filter }
        this.filter_prev = { ...this.filter }
        if (this.filter.applicant_id && this.filter.applicant_id.length) this.fetchApplicants()
        this.preload()
      },
      immediate: true,
      deep: true,
    },
    'filter.object_id' () {
      if (this.filter.applicant_id && this.filter.applicant_id.length)
        this.fetchFilterApplicants()
    },
    'filter.client_id' () {
      if (this.filter.client_id.length) {
        this.filter.object_id = this.objects.map(o => o.object_id).filter(object_id => this.filter.object_id.includes(object_id))
      }
    },
  },
}
